import { AlertCircle, ArrowRight, Brain, Check, Clock, DollarSign, FileText, Mail, Shield, Zap } from 'lucide-react';
import React, { useState } from 'react';

const PricingSection = () => {
	const [isYearly, setIsYearly] = useState(false);

	const handleToggle = () => {
		setIsYearly(!isYearly);
	};

	return (
		<section id="pricing" className="py-20">
			<div className="container mx-auto px-6">
				<h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Simple, Transparent Pricing</h2>
				<div className="max-w-md mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
					<div className="px-6 py-8">
						<h3 className="text-2xl font-bold text-center mb-4">hoomi for Your Home</h3>

						{/* Toggle Button Above the Price */}
						<div className="flex justify-center mb-4">
							<div className="inline-flex items-center">
								<div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
									<input type="checkbox" id="pricing-toggle" className="absolute w-8 h-4 transition-colors border duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-teal-600 peer-checked:border-teal-600 peer-checked:before:bg-teal-600" checked={isYearly} onChange={handleToggle} />
									<label htmlFor="pricing-toggle" className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 peer-checked:translate-x-full peer-checked:border-teal-600 peer-checked:before:bg-teal-600"></label>
								</div>
								<label htmlFor="pricing-toggle" className="ml-3 font-light text-gray-700 cursor-pointer select-none">
									{isYearly ? 'Yearly Billing' : 'Monthly Billing'}
								</label>
							</div>
						</div>

						{/* Pricing Section */}
						<div className="text-center mb-6">
							<span className="text-5xl font-bold">{isYearly ? '$99' : '$9.99'}</span>
							<span className="text-xl text-gray-600">/{isYearly ? 'year' : 'month'}</span>
						</div>
						<p className="text-center text-teal-600 font-semibold mb-6">{isYearly ? 'Save 17% with yearly billing!' : '.'}</p>

						<ul className="space-y-4 mb-8">
							{['Unlimited document uploads', 'AI-powered interpretations', 'Email-based interaction'].map((feature, index) => (
								<li key={index} className="flex items-center">
									<Check className="h-5 w-5 text-green-500 mr-2" />
									<span>{feature}</span>
								</li>
							))}
						</ul>
						<a href={isYearly ? 'https://buy.stripe.com/eVaeXO9LmgL5brWeUW' : 'https://buy.stripe.com/14k2b25v60M7brWdQR'} className="w-full block bg-teal-600 text-white py-3 rounded-full text-center hover:bg-teal-700 transition duration-300 transform hover:scale-105">
							Get Early Access
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PricingSection;
