import { AlertCircle, ArrowRight, Brain, Check, Clock, DollarSign, FileText, Mail, Shield, Zap } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import PricingSection from './PricingSection';

const LandingPage = () => {
	const [isIntersecting, setIsIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));
		observer.observe(document.querySelector('#cta-section'));
		return () => observer.disconnect();
	}, []);
	const documentTypes = ['Health insurance', 'Travel insurance', 'Phone agreement', 'Internet agreement', 'Mortgage', 'Lease agreement', 'Car insurance', 'Credit card terms', 'Employment contract', 'Subscription services'];

	return (
		<div className="min-h-screen bg-gradient-to-b from-gray-50 to-white text-gray-900 font-sans">
			<header className="fixed w-full bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg z-50">
				<nav className="container mx-auto px-6 py-4 flex justify-between items-center">
					<div className="text-2xl font-bold text-teal-600">hoomi</div>
					<div className="hidden md:flex space-x-8">
						<a href="#features" className="text-gray-700 hover:text-teal-600 transition duration-300">
							Features
						</a>
						<a href="#how-it-works" className="text-gray-700 hover:text-teal-600 transition duration-300">
							How It Works
						</a>
						<a href="#impact" className="text-gray-700 hover:text-teal-600 transition duration-300">
							Long-Term Impact
						</a>
						<a href="#pricing" className="text-gray-700 hover:text-teal-600 transition duration-300">
							Pricing
						</a>
					</div>
					<a href="#pricing" className="bg-teal-600 text-white px-6 py-2 rounded-full hover:bg-teal-700 transition duration-300">
						Get Early Access
					</a>
				</nav>
			</header>

			<main className="pt-24">
				<section className="container mx-auto px-6 py-16 md:py-24">
					<div className="max-w-4xl mx-auto text-center">
						<h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
							Decode Your Documents <span className="text-teal-600">with AI</span>
						</h1>
						<p className="text-xl md:text-2xl text-gray-700 mb-12 leading-relaxed">hoomi simplifies complex contracts and agreements, potentially saving you thousands in hidden fees and countless hours of confusion.</p>
						<a href="#pricing" className="inline-flex items-center bg-teal-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-teal-700 transition duration-300 transform hover:scale-105">
							Get Early Access <ArrowRight className="ml-2" />
						</a>
					</div>
				</section>

				<section id="features" className="bg-teal-50 py-20">
					<div className="container mx-auto px-6">
						<h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Why Choose hoomi?</h2>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-12">
							<FeatureCard icon={<Zap className="w-12 h-12 text-teal-600" />} title="Instant Clarity" description="Get clear explanations of complex clauses in seconds, not hours." />
							<FeatureCard icon={<AlertCircle className="w-12 h-12 text-teal-600" />} title="Catch Hidden Fees" description="Identify potential costs buried in the fine print before they surprise you." />
							<FeatureCard icon={<Mail className="w-12 h-12 text-teal-600" />} title="Email Integration" description="Simply forward your documents and ask questions via email. No new apps to learn." />
						</div>
					</div>
				</section>

				<section id="documents" className="py-20">
					<div className="container mx-auto px-6">
						<h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Documents We Can Help With</h2>
						<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
							{documentTypes.map((doc, index) => (
								<div key={index} className="flex items-center bg-white p-4 rounded-lg shadow-md">
									<FileText className="w-6 h-6 text-[#36eee0] mr-3" />
									<span className="text-[#4c5270]">{doc}</span>
								</div>
							))}
						</div>
					</div>
				</section>

				<section id="how-it-works" className="py-20">
					<div className="container mx-auto px-6">
						<h2 className="text-3xl md:text-4xl font-bold text-center mb-16">How hoomi Works</h2>
						<div className="max-w-3xl mx-auto">
							<StepCard number="1" title="Email Your Documents" description="Forward any contract or agreement to your dedicated hoomi email address." />
							<StepCard number="2" title="Ask Your Question" description="Send an email with any question about your documents." />
							<StepCard number="3" title="Get Clear Answers" description="Receive a plain-English explanation directly in your inbox." />
						</div>
					</div>
				</section>

				<PricingSection />

				<section id="cta-section" className="bg-teal-600 text-white py-20">
					<div className="container mx-auto px-6 text-center">
						<h2 className="text-3xl md:text-4xl font-bold mb-8">Ready to Simplify Your Life?</h2>
						<p className="text-xl mb-12">Join the growing community of hoomi users and take control of your documents.</p>
						<a href="#pricing" className="inline-flex items-center bg-white text-teal-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 transform hover:scale-105">
							Get Early Access Now <ArrowRight className="ml-2" />
						</a>
					</div>
				</section>
			</main>

			<footer className="bg-gray-900 text-white py-12">
				<div className="container mx-auto px-6 text-center">
					<p>&copy; 2024 hoomi. All rights reserved. Simplifying the fine print, one document at a time.</p>
				</div>
			</footer>

			{!isIntersecting && (
				<div className="fixed bottom-6 right-6 z-50">
					<a href="#princing" className="bg-teal-600 text-white px-6 py-3 rounded-full hover:bg-teal-700 transition duration-300 shadow-lg">
						Get Early Access
					</a>
				</div>
			)}
		</div>
	);
};

const FeatureCard = ({ icon, title, description }) => (
	<div className="flex flex-col items-center text-center">
		<div className="mb-4">{icon}</div>
		<h3 className="text-xl font-semibold mb-2">{title}</h3>
		<p className="text-gray-700">{description}</p>
	</div>
);

const StepCard = ({ number, title, description }) => (
	<div className="flex items-start mb-12">
		<div className="flex-shrink-0 mr-6">
			<div className="w-12 h-12 bg-teal-600 text-white rounded-full flex items-center justify-center text-xl font-bold">{number}</div>
		</div>
		<div>
			<h3 className="text-xl font-semibold mb-2">{title}</h3>
			<p className="text-gray-700">{description}</p>
		</div>
	</div>
);

const ImpactCard = ({ icon, title, description }) => (
	<div className="bg-white p-6 rounded-xl shadow-md">
		<div className="flex justify-center mb-4">{icon}</div>
		<h3 className="text-xl font-semibold text-center mb-2">{title}</h3>
		<p className="text-gray-700 text-center">{description}</p>
	</div>
);

export default LandingPage;
